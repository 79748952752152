import type { CustomElement } from '@integrabeauty/custom-elements';
import html from './index.html';
import styles from './index.scss';

/**
 * Renders shaded stars based on a product's average review score along with a count of reviews.
 */
class ProductRatingStars extends HTMLElement implements CustomElement {
  public static get observedAttributes() {
    return [
      'data-count',
      'data-average',
      'data-product-reviews-url',
      'data-reviews-hash'
    ];
  }

  readonly dataset!: {
    /**
     * Value to determine alignment of stars in comparison to their container
     */
    align: 'center' | 'left' | 'right';

    /**
     * This is not always defined. In some contexts the app key is specified during server side
     * rendering such as in the real results page.
     */
    appKey: string;

    /**
     * Average rating
     */
    average: string;

    /**
     * Number of reviews
     */
    count: string;

    /**
     * Associated product-id used to update the yotpo-reviews element
     */
    productId: string;

    /**
     * Hash of the product's review section
     */
    reviewsHash: string;

    /**
     * URL for the product detail which includes the anchor hash to focus the reviews section on
     * page load. Can be used on non-product pages to construct full path, query parameters and hash
     * required.
     */
    reviewsUrl: string;
  };

  public shadowRoot!: ShadowRoot;

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    this.render();
  }

  public attributeChangedCallback(_name: string, oldValue: string, newValue: string) {
    if (this.isConnected && oldValue !== newValue) {
      this.render();
    }
  }

  private render() {
    if (!this.dataset.count || !this.dataset.average) {
      return;
    }

    // Set the anchor href. There are multiple scenarios:
    //
    // * link from another page, need both path and hash
    // * link on same page, need only hash
    // * no link, just show stars, but address accessibility by setting role to img

    // TODO: we should not be using multiple data attributes to do this, just use one, and specify
    // the hash only if we only want the hash.

    const anchorElement = this.shadowRoot.querySelector<HTMLAnchorElement>('.rating-stars-url');

    if (this.dataset.reviewsUrl) {
      anchorElement.setAttribute('href', this.dataset.reviewsUrl);
    } else if (this.dataset.reviewsHash) {
      anchorElement.setAttribute('href', this.dataset.reviewsHash);
    } else {
      anchorElement.role = 'img';
    }

    const count = parseInt(this.dataset.count, 10);
    const average = parseFloat(this.dataset.average);

    if (!count || !average) {
      return;
    }

    this.renderStars();

    const countElement = this.shadowRoot.querySelector('.rating-count');
    const formatter = new Intl.NumberFormat('en-US');
    countElement.textContent = formatter.format(count);

    if (count === 1) {
      anchorElement.setAttribute('aria-label', `Rating: ${ average.toFixed(0) } stars.`);
    } else {
      anchorElement.setAttribute('aria-label',
        `Average rating: ${ average.toFixed(1) } based on ${ count } reviews.`);
    }
  }

  private renderStars() {
    // We do not need fallbacks for missing attributes here because we checked in the caller.

    const rating = parseFloat(this.dataset.average);

    // Rendering stars is tricky because using exact numbers produces poor results. The difference
    // is illegible most of the time but it looks bad enough that we do some rounding to get the
    // stars to look more like the rating represented.

    const baseInt = parseInt(this.dataset.average, 10);
    let display = 0;
    if (rating <= baseInt + 0.25) {
      display = baseInt + 0.42;
    } else if (rating <= baseInt + 0.5) {
      display = baseInt + 0.5;
    } else if (rating > baseInt + 0.5 && rating <= baseInt + 0.75) {
      display = baseInt + 0.6;
    } else if (rating >= baseInt + 0.76) {
      display = baseInt + 1;
    }

    const stars = this.shadowRoot.querySelector<HTMLElement>('.rating-stars');
    stars.style.cssText = `--rating: ${display}`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'product-rating-stars': ProductRatingStars;
  }
}

// This element is loaded by a section that may be loaded more than once. This idempotency check is
// required.

if (!customElements.get('product-rating-stars')) {
  customElements.define('product-rating-stars', ProductRatingStars);
}
